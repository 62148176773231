<template>
  <div class="tableAdminExtracurricular">
    <!-- <div>
      <div class="cui__utils__heading mb-0">
        <strong>Rules</strong>
      </div>
      <div class="text-muted mb-3">
        On this page you can view, search, add and delete "Extracurricular"
      </div>
      <hr />
    </div> -->
    <a-modal
      :visible="showConfirmAdd"
      :confirm-loading="loading.updateLoading"
      @ok="handleCreate"
      @cancel="showConfirmAdd = false"
      :maskStyle="{
        opacity: 0.5,
        backgroundColor: 'black'
      }"
      :okText="isEdit ? 'Edit' : 'Add'"
      :okButtonProps="{
        props: {
          shape: 'round'
        }
      }"
      :cancelButtonProps="{
        props: {
          shape: 'round'
        }
      }"
    >
      <h3 slot="title" style="color: #faad14;">
        <a-icon style="font-size: 1.2em" type="warning" /> {{isEdit ? 'EDIT' : 'ADD NEW'}} EXTRACURRICULAR
      </h3>
      <p>Are you sure to {{isEdit ? 'edit' : 'add new'}} extracurricural?</p>
    </a-modal>
    <a-modal
      :visible="visible"
      :title="isEdit ? 'Edit Extracurricular' : 'Create New Extracurricular'"
      :okText="isEdit ? 'Edit' : 'Create'"
      @cancel="handleCancel"
      @ok="validateCreate"
      :okButtonProps="{
        props: {
          disabled: loadingAdd,
        },
      }"
      :cancelButtonProps="{
        props: {
          disabled: loadingAdd,
        },
      }"
    >
      <div
        v-if="loadingAdd"
        style="
          display: flex;
          justify-content: center;
          height: 30vh;
          align-items: center;
        "
      >
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 5em; color: #008080"
            spin
          />
        </a-spin>
      </div>
      <a-form
        v-else
        layout="vertical"
      >
        <a-form-item label="Name">
          <a-input
            size="large"
            v-model="addEkskul.name"
            placeholder="The name of Extracurricular"
          />
        </a-form-item>
        <!-- <a-form-item label="School Year">
          <a-input
            :value="tahunAjaran.tahun + ' ' + tahunAjaran.semester"
            disabled
            placeholder="The name of Extracurricular"
          />
        </a-form-item> -->
        <!-- <a-form-item label="Supervisor">
          <a-select
            placeholder="Select Extracurricular Supervisor"
            v-model="addEkskul.supervisor"
          >
            <a-select-option v-for="teacher in teachersAddSpvList" :key="teacher.id" :value="teacher.id">{{teacher.nama}}</a-select-option>
          </a-select>
        </a-form-item> -->
        <!-- <a-form-item label="Type">
          <a-select
            size="large"
            placeholder="Select Extracurricular Type"
            @change="handleSelectChange"
            v-model="addEkskul.type"
          >
            <a-select-option value="wajib"> Required </a-select-option>
            <a-select-option value="opsional">
              Optional
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Levels" v-if="addEkskul.type === 'wajib'">
          <a-checkbox-group size="large" class="w-100" v-model="addEkskul.daftarLevel">
            <a-checkbox v-for="level in levels" :key="level.id" :value="level.id">
              {{level.nama}}
            </a-checkbox>
          </a-checkbox-group>
        </a-form-item> -->
        <a-form-item label="Description">
          <a-textarea
            v-model="addEkskul.desc"
            allow-clear
            :autoSize="{ minRows: 8 }"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <div>
      <div class="d-lg-flex flex-column flex-md-row align-items-center mb-4">
          <a-input
            v-model="search"
            @keyup="searchExtracurricular"
            placeholder="Search by Extracurricular Name..."
            size="large"
            :style="{
              width: !isDesktop ? '100%' : '300px',
            }"
          ></a-input>
        <!-- <a-tooltip title="Extracurricular Type">
          <a-select
            size="large"
            placeholder="Select Type"
            class="my-lg-0 my-3"
            :style="{
              'margin-left': isDesktop ? '20px' : '',
              width: !isDesktop ? '100%' : '120px',
            }"
            @change="handleSelectType"
            :default-value="selectedType"
          >
            <a-select-option
              value=""
              >All</a-select-option
            >
            <a-select-option
              v-for="(item, i) in ['opsional', 'wajib']"
              :key="i"
              :value="item"
              >{{ item === 'opsional' ? 'Optional' : item === 'wajib' ? 'Required' : item }}</a-select-option
            >
          </a-select>
        </a-tooltip> -->
        <!-- <a-tooltip title="School Year">
          <a-select
            size="large"
            placeholder="Select School Year"
            :style="{
              'margin-left': isDesktop ? '20px' : '',
              width: !isDesktop ? '100%' : '200px',
            }"
            @change="handleSelectSchoolYear"
            :default-value="selectedSchoolYear"
          >
            <a-select-option
              value=""
              >All</a-select-option
            >
            <a-select-option
              v-for="item in schoolYearList"
              :key="item.key"
              :value="item.key"
              >{{ item.semester }}, {{ item.tahun }}</a-select-option
            >
          </a-select>
        </a-tooltip> -->
        <div class="mb-3 mb-lg-0 mt-3 mt-lg-0 mr-0 mx-lg-3">
          <a-button
            :disabled="loadingRefresh"
            @click.prevent="refreshTable"
            size="large"
            class="w-100"
          >
            <a-icon type="sync" :spin="loadingRefresh" />
            REFRESH</a-button
          >
        </div>
        <div class="ml-auto text-center">
          <a-button
              @click.prevent="showModalAddExtracurricular()"
              type="primary"
              icon="plus"
              :block="isMobile"
              size="large"
              class="w-100"
              >ADD</a-button
            >
        </div>
      </div>
    </div>
    <a-table
      :columns="columns"
      :data-source="dataTable"
      :loading="loadingTable"
      :pagination="pagination"
      @change="handleTableChange"
      bordered
    >
      <div slot="description" slot-scope="value">
        {{value || '-'}}
      </div>
      <!-- <div slot="Name" slot-scope="value, record">
        <a @click.prevent="toDetailEkskul(record.key)">{{value}}</a>
      </div> -->
      <!-- <div
        v-if="editExtracurricular"
        slot="Name"
        slot-scope="text, record"
      >
        <a-input
          :value="text"
          style="height: 50px; width: 400px"
          @change="
            (e) => handleEditChange(e.target.value, record.key, 'Name')
          "
        />
      </div>
      <div v-else slot="Name" slot-scope="text">
        <span>{{ text }}</span>
      </div>
      <div v-if="editExtracurricular" slot="Type" slot-scope="text, record">
        <a-select @change="e => handleEditChange(e, record.key, 'Type')" style="width: 100px">
        <a-select-option
          v-for="item in extracurricularTypes"
          :key="item.key"
          :value="item.tipe"
          default-value="All"
          >{{ item.type }}</a-select-option
        >
        </a-select>
      </div>
      <div v-else slot="Type" slot-scope="text">
        <span>{{ text }}</span>
      </div>
      <div v-if="editExtracurricular" slot="Description" slot-scope="text, record">
        <a-input
          :value="text"
          style="height: 50px; width: 80px"
          @change="(e) => handleEditChange(e.target.value, record.key, 'Description')"
        />
      </div>
      <div v-else slot="Description" slot-scope="text">
        <span>{{ text }}</span>
      </div> -->
      <div class="d-flex justify-content-center" slot="Actions" slot-scope="text, record">
        <a @click.prevent="showModalAddExtracurricular(record)" class="btn btn-outline-warning mx-2"><a-icon class="mr-2" type="edit" />Edit</a>
        <a @click.prevent="deleteExtracurricular(record.key)" class="btn btn-outline-danger mx-2"><a-icon class="mr-2" type="delete" />Delete</a>
      </div>
      <a-icon slot="filterIcon" type="search" />
    </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    align: 'center',
    // width: 200,
    // sorter: true,
    // scopedSlots: {
    // customRender: 'Name',
    // filterIcon: 'filterIcon',
    // },
  },
  // {
  //   title: 'Type',
  //   dataIndex: 'Type',
  //   align: 'center',
  //   // width: 150,
  //   // scopedSlots: {
  //   //   customRender: 'Type',
  //   // },
  // },
  {
    title: 'Description',
    dataIndex: 'description',
    align: 'center',
    sorter: true,
    width: 300,
    ellipsis: true,
    scopedSlots: {
      customRender: 'description',
    },
  },
  // {
  //   title: 'Supervisor',
  //   dataIndex: 'Coach',
  //   align: 'center',
  //   // width: 400,
  //   // scopedSlots: {
  //   //   customRender: 'Coach',
  //   // },
  // },
  // {
  //   title: 'School Year',
  //   dataIndex: 'SchoolYear',
  //   align: 'center',
  //   // width: 200,
  //   // scopedSlots: { customRender: 'SchoolYear' },
  // },
  {
    title: 'Actions',
    dataIndex: 'Actions',
    align: 'center',
    // width: 200,
    scopedSlots: { customRender: 'Actions' },
  },
]

export default {
  data() {
    return {
      loadingTable: false,
      visible: false,
      loadingAdd: false,
      modalVisible: false,
      loadingCancel: false,
      loadingDelete: false,
      confirmLoading: false,
      loadingRefresh: false,
      editExtracurricular: false,
      columns,
      savedData: [],
      dataTable: [],
      pagination: {},
      selectedType: '',
      selectedSchoolYear: '',
      search: '',
      extracurricularTypes: [],
      schoolYearList: [],
      extracurricularCoachList: [],
      addEkskul: {
        name: '',
        supervisor: '',
        type: 'wajib',
        desc: '',
        daftarLevel: [],
      },
      teachersAddSpvList: [],
      showConfirmAdd: false,
      loading: {
        updateLoading: false,
      },
      tahunAjaran: {},
      levels: [],
      order: 'ASC',
      sortBy: null,
      isEdit: false,
      idEdit: null,
    }
  },
  methods: {
    validateCreate() {
      if (!this.addEkskul.name) {
        return this.$notification.error({
          message: 'Sorry.',
          description: 'Name must be filled.',
        })
      }
      // else if (this.addEkskul.type === 'wajib' && !this.addEkskul.daftarLevel.length) {
      //   return this.$notification.error({
      //     message: 'Sorry.',
      //     description: "Please fill level's checkbox if extracurricular's type is sets to be required.",
      //   })
      // }
      this.showConfirmAdd = true
    },
    toDetailEkskul(idEkskul) {
      this.$router.push({ name: 'Extracurricular Detail Admin', params: { id: idEkskul } })
    },
    async fetchExtracurricular(
      params = {
        // idTahunAjaran: this.selectedSchoolYear,
      },
    ) {
      try {
        this.loadingTable = true
        const data = await this.$store.dispatch('admin/FETCH_ALL_EXTRACURRICULAR', {
          page: this.pagination.current || 1,
          order: this.order,
          sortBy: this.sortBy,
          search: this.search,
          type: this.selectedType,
          // idTahunAjaran: params.idTahunAjaran,
        })
        // console.log('data fetchExtracurricular', data)
        const pagination = { ...this.pagination }
        pagination.total = data.total
        // this.tahunAjaran = data.tahun_ajaran
        this.loadingTable = false
        this.dataTable = data.ekstrakurikuler.map((row) => {
          // let type = row.tipe
          // if (row.tipe === 'wajib') {
          //   type = 'Required'
          // } else if (row.tipe === 'opsional') {
          //   type = 'Optional'
          // }
          return {
            key: row.id,
            name: row.nama,
            description: row.deskripsi,
            // Type: type,
            // Coach: row.pembina,
            // SchoolYear: row.tahun_ajaran,
          }
        })
        this.pagination = pagination
        this.savedData = this.dataTable
        this.loadingRefresh = false
        this.loading.updateLoading = false
        this.showConfirmAdd = false
        this.visible = false
        this.isEdit = false
        this.idEdit = null
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    refreshTable() {
      this.loadingRefresh = true
      this.fetchExtracurricular()
    },
    handleSelectChange(value) {
      if (value === 'opsional') {
        this.addEkskul.daftarLevel = []
      }
    },
    deleteExtracurricular(key) {
      this.loadingDelete = true
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to delete this extracurricular ? your data will be lost</div>
        ),
        onOk: () => {
          this.$store.dispatch('admin/DELETE_EXTRACURRICULAR', {
            idEkskul: key,
          })
            .then(isDeleted => {
              this.loadingDelete = false
              if (isDeleted) {
                this.$notification.success({
                  message: 'Success',
                  description:
                    'Extracurricular has been removed',
                })
                this.fetchExtracurricular()
              } else {
                this.$notification.error({
                  message: 'Error.',
                  description: 'Extracurricular cannot be deleted',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDelete = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Remove',
      })
    },
    toEditExtracurricular() {
      this.editExtracurricular = true
    },
    save() {
      this.modalVisible = true
    },
    cancelEdit() {
      this.loadingCancel = true
      this.fetchExtracurricular()
      setTimeout(() => {
        this.editExtracurricular = false
        this.loadingCancel = false
      }, 2000)
    },
    async handleCreate() {
      try {
        const newExtracurricular = {
          nama: this.addEkskul.name,
          deskripsi: this.addEkskul.desc,
          // tipe: this.addEkskul.type,
        }
        console.log(newExtracurricular, this.idEdit)
        // const { daftarLevel } = this.addEkskul
        this.loading.updateLoading = true
        if (this.isEdit) {
          await this.$store
            .dispatch('admin/EDIT_EXTRACURRICULAR', {
              savedData: newExtracurricular,
              idEkskul: this.idEdit,
              // idPembina: this.addEkskul.supervisor,
              // daftarLevel,
            })
          this.addEkskul = {
            name: '',
            supervisor: '',
            type: 'wajib',
            desc: '',
          }
          this.$notification.success({
            message: 'Success',
            description:
              'Extracurricular has been edited',
          })
        } else {
          await this.$store
            .dispatch('admin/CREATE_EXTRACURRICULAR', {
              newExtracurricular,
              // idPembina: this.addEkskul.supervisor,
              // daftarLevel,
            })
          this.addEkskul = {
            name: '',
            supervisor: '',
            type: 'wajib',
            desc: '',
          }
          this.$notification.success({
            message: 'Success',
            description:
              'Extracurricular has been added',
          })
        }
        this.fetchExtracurricular()
      } catch (err) {
        this.$notification.error({
          message: 'failed',
          description:
            "Extracurricular can't be edited",
        })
      }
    },
    handleEditChange(value, key, column) {
      const newData = [...this.dataTable]
      const target = newData.filter(item => key === item.key)[0]
      if (target) {
        target[column] = value
        this.savedData = newData
      }
    },
    showModalAddExtracurricular(data) {
      this.visible = true
      if (data) {
        this.idEdit = data.key
        this.isEdit = true
        this.addEkskul = {
          ...this.addEkskul,
          name: data.name,
          desc: data.description,
        }
      }
      // this.$store.dispatch('admin/FETCH_TEACHER_NOT_EXTRACURRICULAR_SUPERVISOR_LIST')
      //   .then(data => {
      //     this.teachersAddSpvList = data
      //     this.addEkskul.supervisor = this.teachersAddSpvList[0].id
      //   })
    },
    validateDataInput(data) {
      let isInvalid = false
      let message = ''
      for (let i = 0; i < data.length; i++) {
        // console.log(data[i])
        if (data[i].Description === '-') {
          data[i].Description = null
        }
        if (data[i].Type === '-' || data[i].Type === 'All') {
          data[i].Type = null
        }
        if (data[i].Score === '-') {
          data[i].Score = null
        }
        if (isNaN(Number(data[i].Score))) {
          isInvalid = true
          message = `Sorry, ${data[i].Subjects}'s Score must be numbers. ${i}`
          break
        } else if (Number(data[i].Score) > 100 || Number(data[i].Score) < 0) {
          isInvalid = true
          message = `Sorry, ${data[i].Subjects}'s Score must be between 0 and 100.`
          break
        }
      }
      return {
        isInvalid,
        message,
      }
    },
    handleOk() {
      // console.log(this.savedData)
      const newData = [...this.savedData]
      if (newData.length) {
        const validation = this.validateDataInput(newData)
        if (validation.isInvalid) {
          this.$notification.error({
            message: 'Sorry.',
            description: validation.message,
          })
          this.modalVisible = false
        } else {
          const target = newData.map((row) => {
            return {
              id: row.key,
              description: row.Description,
              type: row.Type,
              score: Number(row.Score),
            }
          })
          // console.log('Berhasil', target)
          this.confirmLoading = true
          this.$store
            .dispatch('admin/UPDATE_REWARD_PUNISMENT', {
              data: target,
            })
            .then(res => {
              setTimeout(() => {
                this.fetchExtracurricular()
                this.editExtracurricular = false
                this.modalVisible = false
                this.confirmLoading = false
                if (res.isSuccess) {
                  this.$notification.success({
                    message: 'Success',
                    description: 'Extracurricular has been updated',
                  })
                } else {
                  this.$notification.error({
                    message: 'Sorry',
                    description: 'Extracurricular has not been updated',
                  })
                }
              }, 1000)
            })
        }
      } else {
        this.editExtracurricular = false
        this.confirmLoading = true
        setTimeout(() => {
          this.$notification.error({
            message: 'Sorry',
            description: 'Extracurricular has not been updated',
          })
          this.modalVisible = false
          this.confirmLoading = false
        }, 1000)
      }
    },
    handleCancel() {
      this.visible = false
      this.modalVisible = false
      this.idEdit = null
      this.isEdit = false
      this.addEkskul = {
        ...this.addEkskul,
        name: '',
        desc: '',
      }
    },
    searchExtracurricular() {
      if (this.search.length > 2) {
        this.fetchExtracurricular({
          page: 1,
          schoolYear: this.selectedSchoolYear,
        })
      } else {
        // this.fetchExtracurricular({
        //   type: this.selectedType,
        //   schoolYear: this.selectedSchoolYear,
        //   search: this.search,
        // })
      }
    },
    handleSelectType(value) {
      this.selectedType = value
      this.fetchExtracurricular({
        type: this.selectedType,
        idTahunAjaran: this.selectedSchoolYear,
      })
    },
    handleSelectSchoolYear(value) {
      // console.log('jalan handleSelectSchoolYear')
      this.selectedSchoolYear = value
      this.fetchExtracurricular({
        idTahunAjaran: this.selectedSchoolYear,
        type: this.selectedType,
      })
    },
    handleTableChange(pagination, filters, sorter) {
      this.sortBy = sorter.columnKey === 'Name' ? 'nama' : sorter.columnKey
      this.pagination = { ...this.pagination, current: pagination.current }
      if (sorter.order === 'ascend') {
        this.order = 'ASC'
      } else if (sorter.order === 'descend') {
        this.order = 'DESC'
      } else {
        this.order = 'ASC'
      }
      this.fetchExtracurricular()
    },
    async fetchLevels() {
      try {
        const data = await this.$store.dispatch('master/FETCH_LEVEL', { page: 'all' })
        this.levels = data.map(level => {
          return {
            nama: level.nama,
            id: level.id,
          }
        })
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
  },
  created() {
    // this.fetchLevels()
    this.fetchExtracurricular()
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>

<style lang="scss">
.tableAdminExtracurricular {
  .ant-spin-nested-loading .ant-spin-container .ant-table {
    overflow-x: auto;
    table {
      @media (max-width: 769px) {
        width: auto;
      }
    }
  }
}
</style>
